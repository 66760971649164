import type { FabricPath, FabricShareInfo, Shape } from './typing.ts';
import {
  DEFAULT_FABRIC_HEIGHT,
  DEFAULT_FABRIC_WIDTH,
  getMMNum,
  isAbsoluteURL,
  isAliyunOSSUrl,
  isBase64Image,
  joinUrl,
  randomId
} from '@repo/utils';

export function getFabricPathConfigure(shape: Shape): Partial<FabricPath> {
  const fabricPath: Partial<FabricPath> = {};
  if (shape.width && shape.height && shape.dpm) {
    fabricPath.width = getMMNum(shape.dpm.x, shape.width);
    fabricPath.height = getMMNum(shape.dpm.y, shape.height);
  }
  fabricPath.width ||= DEFAULT_FABRIC_WIDTH;
  fabricPath.height ||= DEFAULT_FABRIC_HEIGHT;
  if (typeof shape.name === 'string' && /\.png$/i.test(shape.name)) {
    fabricPath.isTransparent = true;
  }
  return fabricPath;
}

export function getFabricPath(shape: Shape, baseUrl: string, resolution: 'sd' | 'hd'): FabricPath {
  let fabricPath: FabricPath = {
    id: shape.id
  };
  const url = isAbsoluteURL(shape.name) || isBase64Image(shape.name)
    ? shape.name
    : joinUrl(baseUrl, `/fabric/${shape.name}`);
  
  if (shape.name.endsWith('.sfab')) {
    fabricPath.model3dPath = url;
  } else {
    fabricPath.xhrPath = isAliyunOSSUrl(url) && resolution === 'sd' ? `${url}?x-oss-process=image/resize,m_fixed,h_512,w_512,limit_0` : url;
    fabricPath = { ...fabricPath, ...getFabricPathConfigure(shape) };
  }
  return fabricPath;
}

export function getPieceShapeMap(shapeList: Shape[]) {
  const map = new Map<string, Shape>();
  const validShape = shapeList.filter(shape => !!shape.piece);

  validShape.forEach(shape => {
    map.set(shape.piece, {
      ...shape,
      id: randomId(),
      name: shape.name
    });
  });
  return map;
}


export function mergeShareFabricInfo(shapeList: Shape[], fabricShareInfo: Omit<FabricShareInfo, 'mask' | 'metaData' | 'doc' | 'enterprise_id'>) {
  const info = fabricShareInfo.info;
  return shapeList.map(item => {
    if (item.type && info[item.type]) {
      return {
        ...item,
        ...info[item.type]
      };
    } else {
      // 兼容老的没有属性的分享
      return {
        ...item,
        ...info['main']
      };
    }
  });
}
