function randomId() {
  return Math.floor(Math.random() * 4000 + 1000);
}

function trimSlashes(uri: string) {
  uri = uri.replace(/^\/+/, ''); // Remove leading slashes
  uri = uri.replace(/\/+$/, ''); // Remove trailing slashes
  return uri;
}

function joinUrl(baseUrl: string, ...uris: string[]) {
  baseUrl = baseUrl.replace(/\/+$/, '');

  const joinedUris = uris.map(uri => trimSlashes(uri));

  const fullURL = [baseUrl, ...joinedUris].join('/').replace(/\/+$/, '');
  return fullURL;
}

function adjustProtocol(url: string) {
  if (window.location.protocol === 'https:') {
    return url.replace(/^http:/, 'https:');
  }
  return url;
}

function setWindowTitle(title: string) {
  document.title = title;
}

/**
 * 获取参数
 * @param name
 * @returns
 */
function getUrlKey(name: string): string | null {
  const url = new URL(location.href);
  const params = new URLSearchParams(url.search);
  const value = params.get(name);
  return value ? decodeURIComponent(value) : null;
}

/**
 * 延迟一段时间
 * @param ms 延迟时间（毫秒）
 * @returns 无
 */
function delay(ms: number): Promise<void> {
  return new Promise(function(resolve) {
    setTimeout(resolve, ms);
  });
}

/**
 * 判断是否为绝对路径URL
 * 1.带有协议的 URL（例如 http://example.com）
 * 2.协议相对的 URL（例如 //example.com）
 * 3.以域名开头的 URL（例如 www.example.com）
 * @param url
 */
function isAbsoluteURL(url: string) {
  const regex = /^(?:[a-zA-Z][a-zA-Z\d+\-.]*:)?\/\/|^[\w-]+\.[\w.-]+\//;
  return regex.test(url);
}

function isAliyunOSSUrl(url: string) {
  const regex = /^https?:\/\/([^.]+)\.oss-([^.]+)\.aliyuncs\.com\/(.+)$/;
  return regex.test(url);
}

/**
 * 判断是否为 Base64 编码的图片数据 URL
 * @param url
 */
function isBase64Image(url: string) {
  const regex = /^data:image\/(png|jpeg|gif|bmp|webp);base64,[a-zA-Z0-9+/]+={0,2}$/;
  return regex.test(url);
}

function isWeixinBrowser() {
  const ua = navigator.userAgent.toLowerCase();

  const isWXWork = ua.match(/wxwork/i) !== null;

  const isWeixin = !isWXWork && ua.match(/MicroMessenger/i) !== null;

  return isWeixin;
}

function isIOS() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

export {
  randomId,
  trimSlashes,
  joinUrl,
  adjustProtocol,
  setWindowTitle,
  getUrlKey,
  delay,
  isAbsoluteURL,
  isAliyunOSSUrl,
  isWeixinBrowser,
  isIOS,
  isBase64Image
};
