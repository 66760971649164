import type {
  FabricPath,
  ID,
  Material,
  MatrixInfo,
  Shape,
  Style3dOptions,
  Style3dSDKInstance,
  UVInfo
} from './typing.ts';
import { ref, reactive } from 'vue';
import {
  getMMNum
} from '@repo/utils';
import { getFabricPath, getPieceShapeMap } from './utils.ts';
import { omit } from 'lodash-es';
import { handleUvAndMatrix, releaseUvInfo } from './matrix.ts';
import { toReactive } from '@vueuse/core';

declare global {
  interface Window {
    Style3D: any;
  }
}
// 0:Current, 1:Front, 2:Left, 3:Right, 4:Back, 5:Custom
type Angle = 0 | 1 | 2 | 3 | 4 | 5;

let style3dSDK: Style3dSDKInstance | null = null;

let baseUrl = '';

function checkStyle3dSDK() {
  if (!style3dSDK) {
    throw new Error('style3dSDK is not initialized');
  }
}

function setTransprarencyRenderEnabled(transprarency: boolean = true) {
  checkStyle3dSDK();
  style3dSDK!.lincpro?.setTransprarencyRenderEnabled(transprarency);
}

async function setMaterialInfoOffset(shapes: Shape[], _materialList: Material[]) {
  if (!shapes || shapes.length == 0) {
    return;
  }

  let copiedUvInfo: UVInfo | null;
  let copiedMatrixInfo: MatrixInfo | null;
  copiedUvInfo = await style3dSDK!.getClothesUvInfo();
  copiedMatrixInfo = await style3dSDK!.getClothesGeometryFabricMatrix();

  let newMatrixInfo: MatrixInfo | null;
  newMatrixInfo = handleUvAndMatrix(shapes, _materialList, copiedUvInfo, copiedMatrixInfo);
  // 使用完数据后，解除引用
  releaseUvInfo(copiedUvInfo);
  copiedUvInfo = null;
  copiedMatrixInfo = null;

  //更新id
  await style3dSDK!.setClothesGeometryFabricMatrix(newMatrixInfo!);
  newMatrixInfo = null;

  for (let i = 0; i < shapes.length; i++) {
    const item = shapes[i]!;
    if (item.pos && (item.pos.x || item.pos.y) && item.dpm && item.width && item.height) {
      // 偏移不为空 进行偏移ou
      const materials = _materialList.filter((v) => v.name === item.piece);
      if (!materials || materials.length === 0) {
      } else {
        // 选中
        await style3dSDK!.selectMaterial(materials[0]!.id, -1, false, 0);
        if (item.pos.x && item.dpm) {
          // x偏移
          const width = getMMNum(item.dpm.x, item.width);
          const x = item.pos.x;
          item.pos.x = width ? Number(((x % width) / width).toFixed(13)) : 0;

          await style3dSDK!.setFabricOffsetX(item.pos.x);
        }
        if (item.pos.y && item.dpm) {
          // x偏移
          const height = getMMNum(item.dpm.y, item.height);
          let y = item.pos.y;
          y = height ? Number(((height - (y % height)) / height).toFixed(13)) : 0;
          await style3dSDK!.setFabricOffsetY(y);
        }
      }
    }
  }
}

const instanceWrapper = reactive({
  instance: null as (ReturnType<typeof createStyle3dSDKInstance> | null)
});

function createStyle3dSDKInstance() {
  const loading = ref(false);
  const isReady = ref(false);

  async function initStyle3d(ops: Style3dOptions) {
    const options = omit(ops, 'baseUrl');
    const dom = document.getElementById('style3d-con');
    style3dSDK = new window.Style3D.Viewer({
      dom,
      displayLevel: options.displayLevel,
      custom: {
        backgroundStyle: {
          color: options.backgroundColor
        }
      },
      events: {
        onReady: () => {
          console.timeEnd('style3d onReady');
          isReady.value = true;
        },
        ...options.events
      },
      config: {
        disableGeoBackPart: true
      }
    });
    setTransprarencyRenderEnabled();
    await style3dSDK!.setImageProcessStrategy(0);
  }

  async function loadSco(scoSrc: string) {
    checkStyle3dSDK();

    isReady.value = false;
    await style3dSDK!.load(scoSrc, null, null, null);
  }

  async function loadFabric(shape: Shape[], fabricRootPath: string, resolution: 'sd' | 'hd' = 'sd') {
    checkStyle3dSDK();
    if (!shape || shape.length === 0) {
      return [];
    }
    baseUrl = fabricRootPath;

    await style3dSDK!.setRenderEnabled(false);
    const map = getPieceShapeMap(shape);
    const materialList = await style3dSDK!.getMaterialList();
    const shapes = materialList
      .filter(({ name }) => map.has(name))
      .map(({ id, name }) => ({ ...map.get(name), id }))
      .filter((shape): shape is Shape => shape !== undefined);

    const materialIds: ID[] = [];
    const fabricsInfoList: FabricPath[] = [];

    for (const shape of shapes) {
      const fabricPath = getFabricPath(shape, baseUrl, resolution);

      materialIds.push(shape.id);
      fabricsInfoList.push(fabricPath);
    }
    await style3dSDK!.changeFabricsByMaterials(materialIds, fabricsInfoList);
    await setMaterialInfoOffset(shape, materialList);
    await style3dSDK!.setRenderEnabled(true);
    await style3dSDK!.clearMaterialSelection();
    await style3dSDK!.clearHistorySteps();

    return shapes;
  }

  async function resetCameraViewState() {
    checkStyle3dSDK();

    // 连续调用两次可以避免CameraViewState不能正确归位的问题
    await style3dSDK!.setCameraViewState(0);
    await style3dSDK!.setCameraViewState(0);
  }

  async function getScreenshot(angle: Angle = 1) {
    return await style3dSDK!.getScreenshot(angle, false, false);
  }

  async function selectMaterial(id: ID) {
    await style3dSDK!.selectMaterial(id, -1, false, 0);
  }

  async function changeFabric(fabricInfo: FabricPath) {
    await style3dSDK!.changeFabricsByMaterials([fabricInfo.id], [fabricInfo]);
  }

  return {
    isReady,
    initStyle3d,
    loading,
    loadSco,
    loadFabric,
    resetCameraViewState,
    getScreenshot,
    selectMaterial,
    changeFabric
  };
}

export function useStyle3d() {
  if (!instanceWrapper.instance) {
    instanceWrapper.instance = toReactive(createStyle3dSDKInstance());
  }
  return instanceWrapper.instance;
}
